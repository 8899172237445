import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

import { BasketIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Basket';
import CartIcon from '@zola/zola-ui/src/components/SvgIcons/Cart';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { ShoppingCartIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ShoppingCart';

import { getCartSize } from 'actions/CartActions';
import { showModal } from 'actions/ModalActions';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';

import styles from './cartContainer.module.less';

const {
  EVENTS: { OPEN_MODAL_EVENT },
} = NAV_DATA;

const CART_PAGE_V2_PATH = '/cart';
const ICON_TITLE = 'Shopping cart';

type CartContainerProps = {
  className?: string;
  hideIfEmpty?: boolean;
  isDesktopNavV3?: boolean;
  navigationLevelPosition?: number;
  onClick?: () => void;
  showBasketIcon?: boolean;
  showOldCartIcon?: boolean;
};

const CartContainer = ({
  className,
  hideIfEmpty,
  isDesktopNavV3 = false,
  navigationLevelPosition,
  onClick,
  showBasketIcon = false,
  showOldCartIcon = false,
}: CartContainerProps): JSX.Element => {
  const dispatch = useDispatch();

  const [cartSize, setCartSize] = useState(0);

  const openModal = useCallback(() => {
    dispatch(showModal('CART_MODAL_V2', {}, { ariaLabel: 'Cart' }));
  }, [dispatch]);

  const getCartSizeEventHandler = (e: CustomEvent) => {
    const newCartSize = e?.detail || 0;
    setCartSize(newCartSize);
  };

  const openModalEventHandler = useCallback(
    (e: CustomEvent) => {
      if (e.detail === 'CART') {
        openModal();
      }
    },
    [openModal]
  );

  useEffect(() => {
    getCartSize()(dispatch)
      .then((size) => setCartSize(size))
      .catch(() => null);
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('NAV_UPDATE_CART_SIZE', getCartSizeEventHandler as EventListener);
    window.addEventListener(OPEN_MODAL_EVENT, openModalEventHandler as EventListener, false);

    return () => {
      window.removeEventListener('NAV_UPDATE_CART_SIZE', getCartSizeEventHandler as EventListener);
      window.removeEventListener(OPEN_MODAL_EVENT, openModalEventHandler as EventListener);
    };
  }, [openModalEventHandler]);

  const openCart = (e: React.MouseEvent) => {
    e.preventDefault();

    trackNavigationClicked({
      business_category: 'UNATTRIBUTED',
      business_unit: 'UNATTRIBUTED',
      navigation_level_1: 'CART',
      navigation_level_1_position: navigationLevelPosition || 1,
      navigation_type: 'UTILITY',
    });

    if (typeof onClick === 'function') onClick();

    // If on the cart page, reload instead of showing the modal
    const pagePath = window.location.pathname;
    if (pagePath === CART_PAGE_V2_PATH) {
      window.location.reload();
      return;
    }

    openModal();
  };

  const renderIcon = () => {
    if (showBasketIcon) {
      return <BasketIcon title={ICON_TITLE} height={20} width={20} />;
    }
    if (showOldCartIcon) {
      return (
        <CartIcon
          className="nav-util__icon nav-cart__icon"
          title={ICON_TITLE}
          height={40}
          width={40}
        />
      );
    }
    return <ShoppingCartIcon title={ICON_TITLE} height={20} width={20} />;
  };

  return hideIfEmpty && cartSize === 0 ? (
    <></>
  ) : (
    <LinkV2
      aria-label="Open cart"
      className={cx(className, 'top-nav__cart icon-link nav-util__cart', styles.cartContainer, {
        'icon-link-v3': isDesktopNavV3,
      })}
      data-testid="nav-cart"
      href={CART_PAGE_V2_PATH}
      onClick={openCart}
    >
      {renderIcon()}
      {cartSize > 0 && <span className={cx(styles.badge, 'badge')}>{cartSize}</span>}
    </LinkV2>
  );
};

export default CartContainer;
