import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import _debounce from 'lodash/debounce';
import _findIndex from 'lodash/findIndex';
import _uniqBy from 'lodash/uniqBy';
import _isEqual from 'lodash/isEqual';
import { getLocalStorage, setLocalStorage } from '@zola-helpers/client/dist/es/util/storage';
import { isDesktop, notDesktop } from '@zola-helpers/client/dist/es/util/responsive';
import { PrimaryNavIdentifier } from '@zola-helpers/client/dist/es/constants/navConstants';
import insertInArrayIf from '@zola-helpers/client/dist/es/util/insertInArrayIf';
import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';
import usePrevious from '@zola/zola-ui/src/hooks/usePrevious';
import getCategoryPriority from 'components/search/util/getCategoryPriority';
import mapCategoriesToSuggestionResults from 'components/search/util/mapCategoriesToSuggestionResults';
import trackUniversalSearchSuggestionSelection from 'components/search/util/tracking';

import { getUserContext as selectUserContext } from 'selectors/user';
import { fetchUniversalSuggestions } from 'client/v1/universalSearch';

import { XIcon } from '@zola/zola-ui/src/components/SvgIconsV3/X';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import UniversalSearchDropdown from '../UniversalSearchDropdown';

import {
  UniversalSearchCategorySuggestionsView,
  UniversalSearchSuggestionCategories,
  UniversalSearchSuggestionView,
} from '../types';

import {
  Container,
  SearchInput,
  StyledSearchIcon,
  CloseButton,
  ClearButton,
  InputContainer,
  LoadingSpinner,
} from './UniversalSearch.styles';

export type UniversalSearchProps = {
  fixedWidth?: boolean;
  parentWidth?: number;
  onCloseSearch?: () => void;
  activeLinkId?: PrimaryNavIdentifier | '';
};

const ICON_SIZE = 20;
const RECENT_SEARCHES_LIMIT = 3;
const SCREEN_READER_STYLES = {
  position: 'absolute',
  left: '-10000px',
  top: 'auto',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
} as React.CSSProperties;

const UniversalSearch = ({
  fixedWidth,
  parentWidth = 524,
  activeLinkId,
  onCloseSearch,
}: UniversalSearchProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const clearButtonRef = useRef<HTMLButtonElement | null>(null);
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const resultsRef = useRef<HTMLDivElement | null>(null);
  const recentSearchesStorage = getLocalStorage('recentSearches');
  const recentSearches: UniversalSearchCategorySuggestionsView = useMemo(
    () =>
      recentSearchesStorage
        ? JSON.parse(recentSearchesStorage)
        : {
            category: 'RECENT_SEARCH',
            suggestions: [],
            title: 'Recent searches',
          },
    [recentSearchesStorage]
  );
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [rawSearchSuggestions, setRawSearchSuggestions] =
    useState<UniversalSearchCategorySuggestionsView[]>();
  const [flattenedSearchTerms, setFlattenedSearchTerms] = useState(recentSearches.suggestions);
  const [currentlySelected, setCurrentlySelected] = useState<
    UniversalSearchSuggestionView | undefined
  >(undefined);
  const userContext = useSelector(selectUserContext);
  const [sortingPriority, sortingPriorityEnum] = getCategoryPriority(
    userContext.is_guest,
    activeLinkId
  );

  // helper function for finding the index of the current option.
  const findCurrentIndex = useCallback(() => {
    if (!currentlySelected) return -1;
    return _findIndex(
      flattenedSearchTerms,
      (x: UniversalSearchSuggestionView) => x.id === currentlySelected.id
    );
  }, [currentlySelected, flattenedSearchTerms]);

  const handleFocus = () => setIsActive(true);

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    // don't close if clicking clear icon, or clicking search suggestion
    if (
      !clearButtonRef.current?.contains(e.relatedTarget) &&
      !resultsRef.current?.contains(e.relatedTarget)
    )
      setIsActive(false);
  };

  const handleRecentSearches = useCallback(
    (searchItem: UniversalSearchSuggestionView) => {
      recentSearches.suggestions.unshift(searchItem);
      const deDupedSuggestions = _uniqBy(
        recentSearches.suggestions,
        (suggestion) => suggestion.term
      );

      deDupedSuggestions.length = Math.min(deDupedSuggestions.length, RECENT_SEARCHES_LIMIT);
      const suggestions = deDupedSuggestions.map((suggestion) => {
        // we don't need to store icon/isSrp values in recentSearches (in fact, icon breaks the storage since it is JSX)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { icon, isSRP, ...rest } = suggestion;
        return rest;
      });
      setLocalStorage(
        'recentSearches',
        JSON.stringify({
          category: 'RECENT',
          suggestions,
          title: 'Recent searches',
        })
      );
    },
    [recentSearches]
  );

  const handleSearchResultClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    searchItem: UniversalSearchSuggestionView,
    itemCategory: UniversalSearchSuggestionCategories,
    group_position: number
  ) => {
    e.preventDefault();
    handleRecentSearches(searchItem);
    trackUniversalSearchSuggestionSelection({
      query: searchInputValue,
      group_type: itemCategory,
      group_position,
      item_position: _findIndex(
        flattenedSearchTerms,
        (x: UniversalSearchSuggestionView) => x.id === searchItem.id
      ),
      item_category: searchItem.type?.toUpperCase() ?? undefined,
      item_label: searchItem.term.replaceAll('*', ''),
      group_priority_name: sortingPriorityEnum,
      action: 'CLICK',
    });
    window.location.assign(searchItem.destination_url as string);
  };

  const handleSearchSelection = useCallback(() => {
    if (isFetching) {
      window.location.assign(`/search/gifts?q=${searchInputValue}`);
      handleRecentSearches({
        term: searchInputValue,
        destination_url: `/search/gifts?q=${searchInputValue}`,
        type: '',
        secondary_text: '',
        parentCategory: 'SHOP',
      });
      trackUniversalSearchSuggestionSelection({
        query: searchInputValue,
        group_type: 'SHOP',
        item_label: `${searchInputValue} in Shop`,
        group_priority_name: sortingPriorityEnum,
        action: 'ENTER_BEFORE_SUGGESTIONS_LOADED',
      });
    } else if (currentlySelected) {
      const groupPosition = _findIndex(
        rawSearchSuggestions,
        (cat) => cat.category === currentlySelected?.parentCategory
      );
      handleRecentSearches(currentlySelected);
      trackUniversalSearchSuggestionSelection({
        query: searchInputValue,
        group_type: currentlySelected.parentCategory,
        group_position: groupPosition + 1, // 1-indexed
        item_position: findCurrentIndex() + 1, // 1-indexed
        item_category: currentlySelected.type,
        item_label: currentlySelected.term.replaceAll('*', ''),
        group_priority_name: sortingPriorityEnum,
        action: 'ENTER',
      });
      window.location.assign(currentlySelected.destination_url as string);
    }
  }, [
    isFetching,
    currentlySelected,
    searchInputValue,
    rawSearchSuggestions,
    handleRecentSearches,
    findCurrentIndex,
    sortingPriorityEnum,
  ]);

  const handleClearInput = () => {
    setSearchInputValue('');
    setRawSearchSuggestions(undefined);
    setFlattenedSearchTerms([]);
    if (inputRef.current) inputRef.current.focus();
  };

  // handle all key presses required by WAI-ARIA standards
  const handleKeyDown = useCallback(
    (e) => {
      // If ESC, close the dropdown and move focus to next element
      if (e.key === 'Escape') {
        e.preventDefault();
        setIsActive(false);
        const form = (e.target as HTMLInputElement)?.form;
        if (form) {
          const index = Array.prototype.indexOf.call(form, e.target);
          (form.elements[index + 1] as HTMLElement).focus();
          e.preventDefault();
        }
      }
      if (e.key === 'Enter') {
        e.preventDefault();
        // enter key toggles the dropdown open and closed
        handleSearchSelection();
      }
      if (e.key === 'Tab') {
        // DON'T prevent default here, we want
        // the tab key to move the user to the next
        // form element!
        setIsActive(false);
      }

      if (!isFetching) {
        // Disable dropdonw navigation if we're fetching
        switch (e.key) {
          case 'Home':
            // If HOME, move to first element
            e.preventDefault();
            setCurrentlySelected(flattenedSearchTerms[0]);
            break;
          case 'End':
            // If END, move to last element
            e.preventDefault();
            setCurrentlySelected(flattenedSearchTerms[flattenedSearchTerms.length - 1]);
            break;
          case 'ArrowUp': {
            e.preventDefault();
            // if no option has been selected, select last option
            if (!currentlySelected) {
              setCurrentlySelected(flattenedSearchTerms[flattenedSearchTerms.length - 1]);
            }
            // Otherwise, move one option up. If at the top of the list, circle back to the bottom
            const currentIndex = findCurrentIndex();
            if (currentIndex === 0) {
              setCurrentlySelected(flattenedSearchTerms[flattenedSearchTerms.length - 1]);
              return;
            }
            setCurrentlySelected(flattenedSearchTerms[Math.max(currentIndex - 1, 0)]);
            return;
          }
          case 'ArrowDown': {
            e.preventDefault();
            // If no selected option, select the first option
            if (!currentlySelected) {
              setCurrentlySelected(flattenedSearchTerms[0]);
            }

            // Otherwise, move one option down. If at the bottom of the list, circle back to the top
            const currentIndex = findCurrentIndex();
            if (currentIndex === flattenedSearchTerms.length - 1) {
              setCurrentlySelected(flattenedSearchTerms[0]);
              return;
            }
            setCurrentlySelected(
              flattenedSearchTerms[Math.min(currentIndex + 1, flattenedSearchTerms.length - 1)]
            );
            break;
          }
          default:
            break;
        }
      }
    },
    [isFetching, flattenedSearchTerms, currentlySelected, findCurrentIndex, handleSearchSelection]
  );

  useEffect(() => {
    if (isActive) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isActive, handleKeyDown]);

  const handleFetchSuggestions = (newSearchValue: string) => {
    if (newSearchValue) {
      setIsFetching(true);
      fetchUniversalSuggestions(newSearchValue)
        .then((res) => {
          setRawSearchSuggestions(res.categories);
          setIsFetching(false);
        })
        .catch(() => undefined);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSuggestions = useCallback(_debounce(handleFetchSuggestions, 300), []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFetching(true);
    setSearchInputValue(e.target.value);
    debouncedFetchSuggestions(e.target.value);
  };

  useEffectOnce(() => {
    if ((notDesktop() || userContext.is_guest) && inputRef?.current) {
      inputRef.current.focus();
    }
  });

  const mappedCategorySuggestionResults = mapCategoriesToSuggestionResults(
    searchInputValue,
    rawSearchSuggestions,
    sortingPriority,
    !userContext.is_guest
  );

  const suggestionsToRender = (
    searchInputValue && rawSearchSuggestions?.length
      ? mappedCategorySuggestionResults
      : [...insertInArrayIf(!!recentSearches.suggestions.length, recentSearches)]
  ).map((cat) => ({
    ...cat,
    suggestions: cat.suggestions.map((suggestion, i) => ({
      ...suggestion,
      parentCategory: cat.category,
      id: `${cat.category}-${i}`,
    })),
  }));

  const prevSuggestionsToRender = usePrevious(suggestionsToRender);

  useEffect(() => {
    if (!_isEqual(prevSuggestionsToRender, suggestionsToRender)) {
      const flatSearchTerms = suggestionsToRender.reduce(
        (acc, cur) => acc.concat(cur.suggestions),
        [] as UniversalSearchSuggestionView[]
      );
      setFlattenedSearchTerms(flatSearchTerms);
      const firstItem = flatSearchTerms[0];
      const initialHighlighted = ['MARKETPLACE', 'PAPER'].includes(firstItem?.parentCategory)
        ? flatSearchTerms[1]
        : firstItem;
      setCurrentlySelected(initialHighlighted);
    }
  }, [suggestionsToRender, prevSuggestionsToRender]);

  const ariaActiveResultIndex = flattenedSearchTerms.findIndex(
    (term) => currentlySelected?.id === term.id
  );

  return (
    <Container
      data-testid="UniversalSearch"
      fixedWidth={fixedWidth || !!userContext.is_guest}
      isActive={isActive}
      width={parentWidth}
      role="search"
      onSubmit={handleSearchSelection}
    >
      <InputContainer>
        <label htmlFor="search" style={SCREEN_READER_STYLES}>
          Find products, brands, couples, vendors…
        </label>
        <span id="instructions" style={SCREEN_READER_STYLES}>
          Begin typing to search, use arrow keys to navigate, enter to select.
        </span>
        <span aria-live="assertive" style={SCREEN_READER_STYLES}>
          {flattenedSearchTerms.length} suggestions found
        </span>
        {isFetching ? (
          <LoadingSpinner />
        ) : (
          <StyledSearchIcon width={ICON_SIZE} height={ICON_SIZE} />
        )}
        <SearchInput
          id="search"
          type="search"
          ref={inputRef}
          value={searchInputValue}
          autoFocus={notDesktop() || userContext.is_guest}
          fixedWidth={fixedWidth || !!userContext.is_guest}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={isDesktop() ? 'Find products, brands, couples, vendors…' : undefined}
          isActive={isActive}
          autoComplete="off"
          aria-controls=""
          aria-labelledby="search"
          aria-haspopup="listbox"
          aria-expanded={isActive && !isFetching}
          aria-describedby="#instructions"
          aria-activedescendant={
            ariaActiveResultIndex !== -1 ? `#result-${ariaActiveResultIndex}` : undefined
          }
          /* @ts-expect-error: Supported in react 17 https://github.com/necolas/react-native-web/pull/1707 */
          enterKeyHint="search"
        />
        {isActive && searchInputValue && (
          <ClearButton
            type="button"
            onClick={handleClearInput}
            ref={clearButtonRef}
            offsetRight={!!onCloseSearch}
          >
            <XIcon color={COLORS3.BLACK_100} title="Clear" width={ICON_SIZE} height={ICON_SIZE} />
          </ClearButton>
        )}
        {onCloseSearch && (
          <CloseButton type="button" onMouseDown={onCloseSearch} ref={closeButtonRef}>
            Close
          </CloseButton>
        )}
      </InputContainer>
      {isActive && !isFetching && (
        <UniversalSearchDropdown
          ref={resultsRef}
          width={userContext.is_guest ? inputRef.current?.clientWidth || 895 : parentWidth}
          suggestionsToRender={suggestionsToRender}
          currentSelection={currentlySelected}
          onSearchResultClick={handleSearchResultClick}
        />
      )}
      <button type="submit" disabled={!currentlySelected} style={SCREEN_READER_STYLES}>
        Search
      </button>
    </Container>
  );
};

export default UniversalSearch;
